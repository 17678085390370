<template>

<div id="modalJustificOrcadoReal">
    
    <b-modal hide-footer
      id="modalJustificOrcadoReal"
      ref="modalJustificOrcadoReal"
      title="Justificativa Orçado x Realizado"
      size="xl"
    >
    
    <b-row>
      
      <b-col
        md="3"
        xl="3"
      >
        <b-form-group
          label="Centro de Resultado:"          
        >
          <b-form-input
            placeholder=""
            v-model="AD_JUSTOXR.DESCRCENCUS"
            required
            autocomplete="off"
            disabled
          />
        </b-form-group>
      </b-col>


      <b-col
        md="3"
        xl="3"
      >
        <b-form-group
          label="Conta Contabil:"          
        >
          <b-form-input
            placeholder=""
            v-model="AD_JUSTOXR.DESCRCTA"
            required
            autocomplete="off"
            disabled
          />
        </b-form-group>
      </b-col>


      

      <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="Ano:">
                <b-form-select
                    v-model="AD_JUSTOXR.ANO"
                    :options="AnoOption"
                    disabled
                />
              </b-form-group>
        </b-col>


        <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="Mes:">
                <b-form-select
                    v-model="AD_JUSTOXR.MES"
                    :options="MesOption"
                    disabled
                />
              </b-form-group>
        </b-col>



       <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
          
        >
          <b-form-textarea
            placeholder=""
            v-model="AD_JUSTOXR.JUSTIFICATIVA"
            rows="3"
          />
        </b-form-group>
      </b-col>



      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Mitigação:"
          
        >
          <b-form-textarea
            placeholder=""
            v-model="AD_JUSTOXR.MITIGACAO"
            rows="3"
          />
        </b-form-group>
      </b-col>


      <b-col md="2">
              
              <b-form-group label="Prazo">
                <b-form-datepicker
                    v-model="AD_JUSTOXR.PRAZO"
                    today-button
                    label-today-button="Selecionar Hoje"
                    reset-button
                    label-reset-button="Limpar"
                    placeholder="Selecione"
                    :required="true"
                    dropup
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    
                />
              </b-form-group>

            </b-col>


    <b-col
        md="6"
        xl="4"
    >
       <br>
       <div class="DivBotaoLadoLado" >
        <b-button  variant="primary" 
         type="button" @click="Salvar"><span>SALVAR</span></b-button>
   
       </div>

    </b-col>
    </b-row>



   
    </b-modal> 


</div>
  
</template>

<script>
import {
  BModal, BButton, VBModal, BAlert, BCardText,
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol,BFormTextarea,BFormDatepicker,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Spinner from '@/components/Spinner.vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    TabAD_JUSTOXR: Object,
  },
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BCardText,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BFormInput,
    BPagination,
    BCardBody,
    BCol,
    BFormTextarea,
    Multiselect,
    BFormDatepicker,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      AnoOption: [],
      MesOption: [],
      AD_JUSTOXR: {
      },

      perPage: 10,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'codprod', label: 'Codigo',tdClass: 'td50'  },
        { key: 'referencia', label: 'Alternativo'  },
        { key: 'aD_CODESTRAPOLO', label: 'Estruturado'  },
        { key: 'descrprod', label: 'Produto',tdClass: 'td250' },
        { key: 'ativo', label: 'Ativo' },
      ],
      items: [
      ],
    }
  },
  methods: {
    
    async Salvar() {
        Spinner.StarLoad()

        const usuario = this.$session.get('usuario')
        let notification = ""        
        
        this.AD_JUSTOXR.CODCENCUS = Number(this.AD_JUSTOXR.CODCENCUS)
        this.AD_JUSTOXR.CODCTACTB = Number(this.AD_JUSTOXR.CODCTACTB)
        this.AD_JUSTOXR.MES = Number(this.AD_JUSTOXR.MES)
        this.AD_JUSTOXR.ANO = Number(this.AD_JUSTOXR.ANO)

        let URLpost = `/JustificativaVariacaoOrcadoRealizado/IncluirAtualizar?CODUSU=${usuario.codusu}`
        const ParametroJson = JSON.parse(JSON.stringify(this.AD_JUSTOXR))

        
        if(notification == ""){
    
              await apiPortalSky.post(URLpost, ParametroJson, {
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then(response => {
                if (response.status === 200) {
                  notification = { message: " ", alert_type: 'success' }
                } else {
                  notification = ConexaoAPI.MsgFiltroSemRetorno()
                }
              })
                .catch(error => {
                  notification = ConexaoAPI.ValidaErro(error)
                })
       }
        
        
       if(notification !== '') {
          AlertaMsg.FormataMsg(notification)
          this.$emit('AtualizaJustificativas')
          this.$bvModal.hide('modalJustificOrcadoReal');
       }else{
          Spinner.EndLoad()
       }
      },

    Selected(item){
 
        if(item.length > 0)
        {

          if(item[0].codprod !== "" && item[0].codprod != undefined)
          {

            /*let ModalPRODUTO = this.ModalPRODUTO      
            ModalPRODUTO.CODPROD = item[0].codprod
            ModalPRODUTO.DESCRPROD = item[0].descrprod
            ModalPRODUTO.REFERENCIA = item[0].referencia
            ModalPRODUTO.AD_CODESTRAPOLO = item[0].aD_CODESTRAPOLO
            ModalPRODUTO.CODVOL = item[0].codvol

            this.$emit('SelectedModalProd',ModalPRODUTO)
            this.$emit('SetModalPRODUTO')
            this.$bvModal.hide('modalProduto');*/

          }
        }


    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Ano`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.mapearOptionsAno(response.data)
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }



      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Mes`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.mapearOptionsMes(response.data)
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }



      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    mapearOptionsAno(options) {
      for (const opt of options) {
        this.AnoOption.push({
          value: opt.code,
          text: opt.name,
        })
      }
    },
    mapearOptionsMes(options) {
      for (const opt of options) {
        this.MesOption.push({
          value: Number(opt.code),
          text: opt.name,
        })
      }
    }

  },
  mounted() {
    if (this.$route.name != null) {
      this.ConsultarOpcoes()
    }

  },

  watch: {
    TabAD_JUSTOXR: {
      handler(newValue) {
        if (newValue && newValue.DESCRCENCUS) {
          this.AD_JUSTOXR = { ...newValue };
        }
      },
      immediate: true, // Executa o watch imediatamente ao montar o componente
      deep: true, // Observa alterações profundas no objeto
    },
  },

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>